<template>
    <div class="container" style="height: 100%">
        <div class="row flex-grow-0 mb-2">
            <div class="col col-md-4">
                <label class="col" for="codeItem">
                    <span>{{ $t("단위 코드") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <!-- <input v-model="detailInfo.unitCode" type="text" :disabled="!isNewMode" class="form-control"
                        id="codeItem" placeholder="단위 코드"> -->
                    <valid-input
                        :vid="'단위 코드'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.unitCode"
                        :placeholder="$t('단위 코드')"
                        :rules="rules.COMMON_CODE_PUBLIC_RULE"
                        :errorMessage="validMessage.COMMON_CODE_PUBLIC_VALID_MESSAGE"
                        :disabled="!isNewMode"
                    ></valid-input>
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="codeName">
                    <span>{{ $t("단위 코드명") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <!-- <input v-model="detailInfo.codeName" type="text" :disabled="!isEditMode" class="form-control"
                        id="codeName" placeholder="단위 코드명"> -->
                    <valid-input
                        :vid="'단위 코드명'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.codeName"
                        :placeholder="$t('단위 코드명')"
                        :rules="rules.COMMON_NAME_RULE"
                        :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="enabled">
                    <span>{{ $t("사용여부") }}</span>
                </label>
                <div class="col">
                    <select v-model="detailInfo.enabled" :disabled="!isEditMode" id="enabled" class="form-control">
                        <option value="">사용여부선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row flex-grow-0 mb-2">
            <div class="col-md-1" style="text-align: center">
                <label for="equipDesc">설명</label>
            </div>
            <div class="col-md-11">
                <!-- <textarea v-model="detailInfo.desc" type="text" class="form-control" id="equipDesc"
                    :disabled="!isEditMode" style="resize: none; overflow-y: auto;" rows="3"></textarea> -->
                <valid-textarea
                    :inputType="'text'"
                    :inputValue.sync="detailInfo.desc"
                    :NonStatus="true"
                    :rows="3"
                    :classList="'desc-ctn form-control'"
                    :rules="rules.COMMON_DESC_RULE"
                    :disabled="!isEditMode"
                >
                </valid-textarea>
            </div>
        </div>

        <div class="row flex-grow-1 mx-1 mt-2 mb-2" style="height: 60%">
            <!-- TableList -->
            <TableListComponent
                ref="TableListComponent"
                :title="''"
                :columns="columns"
                :rows="detailInfo.codeItems"
                :keyField="'codeItem'"
                :transCodes="transCodes"
                :controls="controls"
                :mode="mode"
                :useMaxHeight="true"
                :toggleFields="['enabled']"
                @button-click="handleListButtonClick"
                :useFilter="false"
                @row-click="handleListItemClick"
                @toggle-changed="handleItemToggle"
            />

            <!-- InPropDetail Popup -->
            <vvo v-slot="v">
                <b-modal ref="CodeItemDetailModal" size="md" centered @hidden="closeCodeItemModal">
                    <template #modal-header>
                        <h5>{{ !tempCodeItem ? "단위 코드 추가" : "단위 코드 편집" }}</h5>
                    </template>
                    <template #modal-footer>
                        <!-- @click="handleAddListItem" -->
                        <button
                            type="button"
                            class="btn btn-primary"
                            style="flex: 1"
                            @click="onValidate(v, handleAddListItem)"
                        >
                            {{ !tempCodeItem ? "추가" : "저장" }}
                        </button>
                        <button type="button" class="btn btn-secondary" @click="closeCodeItemModal" style="flex: 1">
                            닫기
                        </button>
                    </template>
                    <CodeItemDetailPanel
                        ref="CodeItemDetailPanel"
                        :data="detailInfo"
                        :mode="mode"
                        :tempCodeItem="tempCodeItem"
                    />
                </b-modal>
            </vvo>
        </div>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import CodeItemDetailPanel from "./CodeItemDetailPanel.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        TableListComponent,
        CodeItemDetailPanel,
    },
    props: ["detailInfo", "mode"],
    data() {
        return {
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "regist", label: "+", class: "btn-warning" },
            ],
            transCodes: [
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            columns: [
                // Detail 테이블 column
                {
                    label: this.$t("단위코드"),
                    field: "codeItem",
                    type: "text",
                    width: "150px",
                    // compositionFields: ['codeItem', 'codeName']
                },
                {
                    label: this.$t("표시 기호"),
                    field: "codeName",
                    type: "text",
                    thClass: "text-center",
                    width: "100px",
                    // compositionFields: ['codeItem', 'codeName']
                },
                {
                    label: this.$t("설명"),
                    field: "desc",
                    type: "text",
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                    width: "80px",
                },
            ],
            tempCodeItem: null,
            tempCodeItemIndex: null,
            rules,
            validMessage,
            popupMessages,
        };
    },
    async created() {},
    mounted() {},
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
        isNewMode() {
            return this.mode === "new";
        },
    },
    watch: {
        isEditMode: "formattedDesc",
    },
    methods: {
        formattedDesc() {
            if (!this.detailInfo.desc?.trim().length) {
                let codeItemText = this.detailInfo.codeItems
                    .filter((item) => item.enabled == "Y")
                    .map((item) => `${item.codeName}(${item.codeItem})`)
                    .join(",");
                let descText = `${this.detailInfo.codeName}(${this.detailInfo.unitCode}): ${codeItemText}`;
                this.detailInfo.desc = descText;
            }
        },
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    await that.registNew();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            that.tempCodeItemIndex = index;
            const findCodeItem = this.detailInfo.codeItems.find((item) => item.codeItem == row.codeItem);
            await that.$refs.CodeItemDetailModal.show();
            await that.$refs.CodeItemDetailPanel.setData({ ...findCodeItem });
        },
        closeCodeItemModal() {
            const that = this;
            if (that.tempCodeItemIndex !== null || undefined) that.tempCodeItemIndex = null;
            that.$refs.CodeItemDetailModal.hide();
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs["TableListComponent"].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.codeItems = that.detailInfo.codeItems.filter((item) => {
                            return !checked.find((checkedProp) => checkedProp.codeItem === item.codeItem);
                        });
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async registNew() {
            const that = this;

            await that.$refs.CodeItemDetailModal.show();
            await that.$refs.CodeItemDetailPanel.setData(null);
        },
        handleAddListItem() {
            const codeItem = this.$refs.CodeItemDetailPanel.getData();

            if (codeItem) {
                if (this.tempCodeItemIndex !== null || undefined) {
                    this.$set(this.detailInfo.codeItems, this.tempCodeItemIndex, codeItem);
                } else {
                    let check = this.detailInfo.codeItems.find(
                        (item) => item.codeItem === codeItem.codeItem || item.codeName === codeItem.codeName
                    );
                    if (check) {
                        // TODO: Alert Message
                        console.error("TODO: Alert Message");
                        console.error(
                            `In Property is already exists in codeItems. codeItem:${codeItem.codeItem}, codeName:${codeItem.codeName}`
                        );
                    } else {
                        this.$set(this.detailInfo.codeItems, this.detailInfo.codeItems.length, codeItem);
                    }
                }
            }

            this.$refs.CodeItemDetailModal.hide();
        },
        handleItemToggle(key, field, status) {
            const that = this;
            const found = that.detailInfo.codeItems.find((item) => item.codeItem === key);
            found.enabled = status;
        },
    },
};
</script>

<style scope>
.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>
